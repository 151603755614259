<template>

    <body>
        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->

        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Front-Header> </Front-Header>



                <!-- Main Start-->
                <div id="singleBlog">
                    <section class="blog-standard-area blog-details-area pt-100 pb-130">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <div class="blog-standard mt-15">
                                        <div class="blog-item">
                                            <div class="blog-thumb">
                                                <img :src="item.image" :alt="item.title">
                                            </div>
                                            <div class="blog-content white-bg ">
                                                <div class="entry-category">
                                                    <router-link
                                                        :to="{ path: '/blog-category/' + this.xcategory_namesid }"
                                                        style="background-color: ;" rel="category">{{
                                                         this.xxcategory_names  }}</router-link>
                                                </div>
                                                <div class="item d-sm-flex justify-content-between">
                                                    <div class="blog-date">
                                                        <ul style="" class="pl-0">
                                                            <!--<li><i class="fal fa-user"></i> Daniele Custer</li>-->
                                                            <li><i class="fa fa-calendar" aria-hidden="true"></i>
                                                                {{ date }}</li>
                                                        </ul>
                                                    </div>
                                                    <!--<div class="blog-social">
                                        <ul>
                                            <li> <span>Share Now</span></li>
                                            <li> <a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li> <a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li> <a href="#"><i class="fab fa-google"></i></a></li>
                                        </ul>
                                    </div>-->
                                                </div>
                                                <h3 class="title">{{ item.title }}</h3>
                                                <!--<p>{{item.description}}</p>-->
                                                <div v-html="item.description"></div>


                                            </div>
                                        </div>
                                        <!--<div class="blog-quote ">
                            <p>Avoids pleasure itself, because it is pleasure, but because those  who do not know how to pursue pleasure rationally encounter con sequences that are extremely painful again anyone</p>
                            <span>Tony R. Francois</span>
                        </div>
                        <div class="blog-sharing pt-40 d-block d-sm-flex justify-content-between">
                            <div class="blog-tag">   
                                <ul class="pl-0">
                                    <li><span>Popular Tag : </span></li>
                                    <li><a href="#">IT Services,</a></li>
                                    <li><a href="#">Technology,</a></li>
                                    <li><a href="#">Services</a></li>
                                </ul>
                            </div>
                        </div>--->



                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-7 col-sm-9">
                                    <div class="blog-sidebar mt-15">

                                        <section id="widget_tptn_pop-2"
                                            class="widget-odd widget-first widget-1 top_posts_widget widget tptn_posts_list_widget">

                                            <h5 class="widget-title">Related Blogs</h5>
                                            <div class="tptn_posts  tptn_posts_widget tptn_posts_widget2">
                                                <ul>
                                                    <li v-for="related in relatedBlogs" :key="related._id">
                                                        <router-link :to="{ path: '/blog-category/' + related.id }"><img
                                                                width="250" height="250" :src="related.image"
                                                                class="tptn_thumb tptn_featured"
                                                                alt="Find the code &amp; WIN 2021 Ts &amp; Cs"
                                                                title="Find the code &amp; WIN 2021 Ts &amp; Cs"
                                                                srcset=""></router-link>

                                                        <span class="tptn_after_thumb">
                                                            <router-link :to="{ path: '/blog-category/' + related.id }">
                                                                <span class="tptn_title">{{ related.title }}
                                                                </span>
                                                            </router-link>

                                                        </span>
                                                    </li>
                                                </ul>
                                                <div class="tptn_clear"></div>
                                            </div>
                                        </section>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <!-- Main End-->

                <!-- Footer Section -->
                <Front-Footer> </Front-Footer>

            </div>
        </div>

    </body>
</template>

<script>

import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import VueMeta from 'vue-meta';
import Vue from 'vue';
Vue.use(VueMeta)
import { db } from '@/main'

export default {
    components: {
        'Front-Header': Header,
        'Front-Footer': Footer,
        'Loader': Loader,
    },

    data() {
        return {
            meta_title: '',
            meta_description: '',
            meta_keywords: '',
            relatedBlogs: [],
            item: [],
            category: '',
            dataa: '',
            x: '',
            date: '',
            xcategory_names: '',
            xcategory_namesid: '',
            xxcategory_names: '',
            test: []

        }
    },
    metaInfo() {
        return {
            title: this.meta_title,
            keywords: this.meta_keywords,
            meta: [
                { name: 'description', content: this.meta_description },
            ]
        }
    },
    created: function () {
        this.fetchItems();

    },


    methods: {


        async fetchItems() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            var id = this.$route.params.id;
            //var category_id;
            this.xxcategory_names
            await db.collection('blogs').doc(id).get().then((doc) => {
                for (this.x in doc.data().category) {
                    db.collection('categoriesblog').doc(doc.data().category[this.x]).get().then((doc) => {
                        this.xcategory_names = doc.data().name;
                        this.xcategory_namesid = doc.id;
                        console.log(this.xcategory_namesid);
                        console.log("this.xcategory_namesid");
                        this.test.push(doc.data().name);
                        this.xxcategory_names = this.xcategory_names + "   " + this.xxcategory_names;

                    });

                }

            });

            await db.collection('blogs').doc(id).get().then((doc) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.date = doc.data().created.split(",")[0];
                this.item = doc.data();
                console.log(doc.data().category);
                this.meta_title = doc.data().meta_title;
                this.meta_description = doc.data().meta_description;
                this.meta_keywords = doc.data.meta_keywords;
                console.log(doc.id, " => ", doc.data());
                doc.data().category.forEach((value) => {
                    db.collection('categoriesblog').doc(value).get().then((res) => {

                        this.relatedBlogs.push({
                            id: res.id,
                            //category: this.category,
                            title: res.data().name,
                            //description: doc.data().description,
                            image: res.data().image,
                        });

                    });
                });


            });

        }

    }
}
</script>
